import React from 'react'
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import {MdArrowForwardIos} from 'react-icons/md'
import { useState } from 'react';
import api from '../services/api';

class ContactForm extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
          fvorname:'',
          fname:'',
          fmessage:'',
          fmail:'',
          ftelefon:'',
          fdatenschutz:'',
          fbetreff:'Betreff: ',
          formError:'none',
        } 
      }

    
      
      componentDidMount() {
        if(this.props.subject ===undefined || this.props.subject === ''){
            this.setState({fbetreff:'Betreff: Kontaktanfrage'})
            } 
        else if(this.props.subject === 'service') {
            this.setState({fbetreff:'Betreff: Serviceanfrage '})
        } 
        else if(this.props.subject === 'stoerung'){
            this.setState({fbetreff:'Betreff: Störungsmeldung'})
        }
        else if(this.props.subject ==='angebote'){
            this.setState({fbetreff:'Betreff: Angebotsanfrage'})
        }

        else {
            this.setState({fbetreff:'Betreff: ' + this.props.subject})
        }   
      }
      
      //onChange Events for Form fields
      //needs regExp 
      onVornameChange(event) {
        if(event.target.value.match("^[\u00C0-\u017Fa-zA-Z'. ]*$")!=null){
            this.setState({fvorname:event.target.value})
            this.setState({formError:'none'})}
        else this.setState({formError:'Bitte benutze keine Sonderzeichen'})
      }
    
      onNameChange(event) {
        if(event.target.value.match("^[\u00C0-\u017Fa-zA-Z'. ]*$")!=null){
          this.setState({fname:event.target.value})}
         else { alert('oO')}
        
      }
    
      onMessageChange(event) {
        if(event.target.value.match("^[\u00C0-\u017Fa-zA-Z'. ]*$")!=null){
        this.setState({fmessage:event.target.value})}
        
      }
    
      onMailChange(event) {
        this.setState({fmail:event.target.value})
      }
    
      onTelefonChange(event) {
        if(event.target.value.match("^[0-9+]*$")!=null){
            this.setState({ftelefon:event.target.value})
        } else {}
        
      }
    
      onDatenschutzChange(event){
        this.setState({fdatenschutz:event.target.checked.toString()})
      }
    

      handleSubmit(event){
        event.preventDefault();
        //console.log(this.state);
        
        if(!this.state.fdatenschutz){
          this.setState({formError:'Sie müssen den Datenschutzbedingungen zustimmen!'});
        } else {

          api.get('sanctum/csrf-cookie').then(() => {
            api.post('/send-mail',
            {   vorname:this.state.fvorname,
                name:this.state.fname,
                mail:this.state.fmail,
                betreff:this.state.fbetreff,
                text:this.state.fmessage,
                datenschutz:this.state.fdatenschutz
  
            },
            { headers:{
                'content-type' : 'application/json'}
            })
            .then((response) => {
                console.log(response)})
          })
        

           /**  axios({
              method:"POST",
              url:"http://localhost:80/backend/api/contact/mail.php",
              data:this.state
            }).then((response) => {
                console.log(response)
                if(response.data.status === 'success') {
                  //alert("Message Sent.");
                  //console.log("Yppieh Nachricht is raus")
                  this.resetForm();
                } else if(response.data.status === 'fail') {
                  alert("Message failed to send.");
                  //console.log("Dscheisze die Nachricht is net raus");
                  //console.log(response.data.error);
                }
            }).catch((error) =>{ 
                console.log(error); }) **/
          }
    
      }

      resetForm(){
        this.setState({
        fvorname:'',
        fname:'',
        fmessage:'',
        fmail:'',
        ftelefon:'',
        fdatenschutz:'',
        fbetreff:''
        })
      }


    render(){
        return (
        <div className='form'>
            <form onSubmit={this.handleSubmit.bind(this)} method="POST">
          
          
        
                <input type="text" id='vname' name='vorname' placeholder='Vorname' required value={this.state.fvorname} onChange={this.onVornameChange.bind(this)}/>
                <input type="text" id='name' name='name' placeholder='Name' required value={this.state.fname} onChange={this.onNameChange.bind(this)} />
                <input type="email" id='email' name='email' placeholder="Mail@adresse.de" required value={this.state.fmail} onChange={this.onMailChange.bind(this)}/>
                <input type="tel" id='telefon' name='telefon' placeholder="Telefonnummer" required value={this.state.ftelefon} onChange={this.onTelefonChange.bind(this)}/>
                <span className='SUBJECTInput'><input type="text" id='subject' name='subject' placeholder="Betreff:" disabled value={this.state.fbetreff} /></span>
                <textarea  rows='4' cols='20' id='text' name='text' placeholder='Nachricht' required value={this.state.fmessage} onChange={this.onMessageChange.bind(this)}/>
                
                <div className='formLowerSection'>
                    <input type="checkbox" id="datenschutz" name='datenschutz' required value={this.state.fdatenschutz} onChange={this.onDatenschutzChange.bind(this)}/>
                    <label>Ich habe die <NavLink to='/datenschutz' className='contactFormLink'>Datenschutzbestimmungen</NavLink> verstanden und akzeptiere diese.</label>
                    <button className='formbutton' type='submit'>Senden <MdArrowForwardIos className='formIcon' style={{color:'#fbb24c'}}/> </button>
                </div>
            
            </form>
            <div style={{color:"#575656", border:"solid 1px #fbb24c",}}>{this.state.formError!=='none' && this.state.formError }</div>
      </div>
      )}
  
}

export default ContactForm