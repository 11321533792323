import React from 'react'
import { motion } from 'framer-motion'
import {BsMegaphone} from 'react-icons/bs'

import {MdOutlineLocalOffer, MdArrowForwardIos, MdChatBubbleOutline} from 'react-icons/md'


import './About.css'
import { NavLink } from 'react-router-dom'

function About() {
  
  return (
    <motion.div 
    className=''
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opactiy:0}}
>
  <div className='aboutcontent'>
    <div className='aboutHeaderSectionWrapper'>
      <div className='aboutHeaderSection'>
        <div className='aboutTitle'><h2>Wir sind ihr <span style={{color:'#575656'}}>Partner</span></h2>
          <h2> rund um die Immobilie</h2>
          <div className='orangeUnderline'></div>
          <div className='greyUnderline'></div>
        </div>  
          <div className='serviceButtonSection'> 
            <NavLink to={'/kontakt/service'}><button className='servicebutton'>Service anfragen <MdArrowForwardIos className='serviceIcon' style={{color:'#fbbd5c'}}/> </button></NavLink>
          </div>
      </div>
    </div>  

    <div className='aboutdescriptionSection'>
      <div className='aboutdescription'><p>Seit über <span style={{fontWeight:'bold'}}>25 Jahren</span> ist das Fundament gewachsen, die individuellen Wünsche von Bauherren und Kaufinteressenten umzusetzen. Die Bereiche Service und Beratung haben sich erheblich verändert. Inzwischen sind die Aufgaben immer vielfältiger geworden. Die Betreuung von Bauherren mit den verschiedensten, noch zu realisierenden Vorstellungen bleibt ein wesentlicher Inhalt unserer Leistung. Für die Umsetzung dieser qualitativ anspruchsvollen Aufgaben konnten wir Partner mit Kompetenzen in <span style={{fontWeight:'bold'}}>Planung</span>, <span style={{fontWeight:'bold'}}>Statik</span> und <span style={{fontWeight:'bold'}}>Finanzierung</span>, sowie regionale Handwerksfirmen für die einzelnen Gewerke am Bau gewinnen.</p></div>
    </div>  

    <div className='serviceSection'>
        {/*<h2>Service</h2>*/ }
      <div className='serviceCard'>
        <BsMegaphone className='abouticons' style={{color:'#fbbd5c'}}/>
        <h3>Vermarktung</h3>
        <p>Egal ob Eigentümer/in und Interessent/in oder Vermieter/in und Mieter/in, wir bringen Sie zusammen.</p>
      </div>
      <div className='serviceCard'>

        <MdChatBubbleOutline className='abouticons' style={{color:'#fbbd5c'}}/>
        <h3>Beratung</h3>
        <p>Wir bieten das umfangreiche Know-How im gesamten Themenbereich Immobilien und stehen mit unserem Rat an Ihrer Seite.</p>
      </div>
      <div className='serviceCard'>

        <MdOutlineLocalOffer className='abouticons' style={{color:'#fbbd5c'}}/>
        <h3>Verkauf</h3>
        <p>Wir wickeln Ihren Immobilien-Verkauf professionell ab, von der Objektbewertung über das aussagekräftige Exposé bis zum Notarvertrag.</p>
      </div>
    </div>


  </div>

</motion.div>
  )
}

export default About