import './Footer.css'
import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <div className='Footer'>
       {/* <div className='greyBox'></div>
        <div className='orangeBox'></div> */}
          <div className='footerContent'>
            <NavLink className='footerLink' to='/impressum'>Impressum</NavLink>
            <p>&nbsp; &copy; Solidus GmbH 2022</p>
          </div>
    </div>
  )
}

export default Footer