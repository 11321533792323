import React from 'react'

function Datenschutz() {
  return (
    <div className='impressumContent'>
      <div className='impressumSection'>
  
        <h2>Datenschutz</h2> 
        <h4>Datenschutzerklärung für die Webseite www.solidus-leipzig.de, mit allen ihren Unterseiten im nachfolgenden Webseite genannt.</h4>	

        <div className='impressum'> 
          
              <p><span style={{color:'#000'}}>1. Datenschutz im Überblick</span></p>

              Allgemeine Hinweise
              Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Webseite besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
              Die Betreiberin dieser Webseite nimmt den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. 
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift, Telefonnummer oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

              <p><span style={{color:'#000'}}>2. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten</span></p>

              Diese Datenschutz-Information gilt für die Datenverarbeitung durch:

              Solidus GmbH, vertreten durch Falk Donner

              Lausner Weg 17a, 04207 Leipzig, Deutschland
              E-Mail: info@solidus-leipzig.de

              Telefon:   +49 (0) 170 – 5488 566
              Fax: 	     +49 (0) 341 –42 259 53

              <p><span style={{color:'#000'}}>3. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</span></p>

              3.1. Beim Besuch der Webseite

              Beim Aufrufen unserer Webseite www.solidus-leipzig.de werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Webseite gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:

              IP-Adresse des anfragenden Rechners,
              Datum und Uhrzeit des Zugriffs,
              Name und URL der abgerufenen Datei,
              Webseite, von der aus der Zugriff erfolgt (Referrer-URL),
              verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.


              Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet: 

              Gewährleistung eines reibungslosen Verbindungsaufbaus der Webseite,
              Gewährleistung einer komfortablen Nutzung unserer Webseite,
              Auswertung der Systemsicherheit und -stabilität sowie
              zu weiteren administrativen Zwecken. 

              Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.

              Darüber hinaus setzen wir beim Besuch unserer Webseite Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung. 

              3.2. Bei Nutzung unseres Kontaktformulars 

              Bei Fragen jeglicher Art und Abforderung von Objektangeboten bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Webseite bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe des Namens und einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.
              Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.

              <p><span style={{color:'#000'}}>4. Weitergabe von Daten </span></p>

              Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.

              Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:   

              Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
              die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,
              für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
              dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.

              <p><span style={{color:'#000'}}>5. Löschung der personenbezogenen Daten </span></p>

              5.1 Wir speichern Ihre Daten, solange dies für die Erfüllung der o.g. Zwecke erforderlich ist und solange gesetzliche Aufbewahrungsfristen bestehen. Anschließend werden Ihre Daten gemäß unserem Löschkonzept gelöscht. Dies bedeutet, dass einige Daten länger aufgehoben werden als andere Daten. 

              5.2 Wenn Sie zum Beispiel einen Vertrag mit uns schließen, werden der Vertrag und die Korrespondenz mit unseren Mitarbeitern (z.B. dem Gewährleistungsservice bezüglich einer gemeldeten Beanstandung oder ähnlichem) während der Gewährleistungszeit gespeichert, um unsere vertraglich festgeschriebenen Pflichten Ihnen gegenüber zu erfüllen. Daten werden ggf. für den Zeitraum von bis zu 6 Monaten nach Ablauf der jeweils einschlägigen, gesetzlichen Verjährungsfrist gespeichert, um unser Unternehmen im Falle einer Inanspruchnahme zu vertreten. 
              Sofern sich aus den folgenden Hinweisen nichts anderes ergibt, speichern wir die Daten nur solange, wie es zur Erreichung des Verarbeitungszwecks oder für die Erfüllung unserer vertraglichen oder gesetzlichen Pflichten erforderlich ist. Solche gesetzlichen Aufbewahrungspflichten können sich insbesondere aus handels- oder steuerrechtlichen Vorschriften ergeben.

              <p><span style={{color:'#000'}}>6. Cookies </span></p>

              Wir setzen auf unserer Webseite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.

              In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.

              Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Webseite bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.

              Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.

              Zum anderen setzten wir Cookies ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Webseite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.

              Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
              Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen unserer Webseite nutzen können. 
        
              <p><span style={{color:'#000'}}>6.1 Derzeit verwendete Cookies</span></p>
              Funktionell notwendiger Cookie: 
              Art: LocalStorage Name: SolidusOffers Verwendung: Anzeigen von Angeboten,Minimierung der Ladezeiten
              
              <p><span style={{color:'#000'}}>7. Analyse-Tools</span></p>

              Diese Webseite verwendet derzeit keine Analyse-Tools.

              <p><span style={{color:'#000'}}>8. Social Media Plugins</span></p>

              Wir setzen auf unserer Webseite auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO derzeit keine Social Plugins der sozialen Netzwerke Facebook, Twitter und Instagram ein, um unsere Firma hierüber bekannter zu machen. Der hinter einem derartigen Einsatz stehende werbliche Zweck ist jedoch als berechtigtes Interesse im Sinne der DSGVO anzusehen. Die Verantwortung für den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu gewährleisten. 

              <p><span style={{color:'#000'}}>9. Betroffenenrechte</span></p>

              Sie haben das Recht:

              gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
              gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
              gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
              gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
              gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
              gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
              gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.
        
              <p><span style={{color:'#000'}}>10. Widerspruchsrecht</span></p>
       
              Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.

              Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an 

              info@solidus-leipzig.de

              <p><span style={{color:'#000'}}>11. Datensicherheit</span></p>

              Wir verwenden innerhalb des Webseite-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. 
              (In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung (Sha2). Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.

              Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.)


              <p><span style={{color:'#000'}}>12. Aktualität und Änderung dieser Datenschutzerklärung</span></p>

              Diese Datenschutzerklärung ist aktuell gültig und hat den Stand November 2022.
              Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Webseite unter von Ihnen abgerufen und ausgedruckt werden.

              <p>Solidus Leipzig</p>
        </div>
      </div>
    </div>
  )
}

export default Datenschutz