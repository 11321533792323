import React from 'react'
import { motion } from 'framer-motion'
import { useState } from 'react'
import './Offers.css'
import { NavLink } from 'react-router-dom'
import {MdArrowForwardIos} from 'react-icons/md'
import {GiSmartphone} from 'react-icons/gi'
import {AiOutlineMail} from 'react-icons/ai'
import {FaMapMarkerAlt} from 'react-icons/fa'
import axios from 'axios'
import { useEffect } from 'react'
import NoImg from '../img/house_modell.jpg'
import api from '../services/api'
import {ThreeDots} from 'react-loader-spinner'


function Offers() {

  const [offers,setOffers] = useState();
  const [globalLoadError, setglobalLoadError] = useState(false);
  const [axiosError, setaxiosError] = useState();
  const [loading, setLoading] = useState(true);

  const addDataToCache = (cacheName, url, response) =>{
    const data = new Response(JSON.stringify(response))

    if ('caches' in window){
      caches.open(cacheName).then((cache => {
        cache.put(url,data);
        console.log('Data Cached @ '+url+' '+cacheName)
      }));
    }
  };

  //fehler direkt nicht erst angebote 
  useEffect(() => {


    api.get("/api/offers")
    //axios({
      //method:"GET",
      //url:"https://api.solidus-leipzig.de/offers",
      //url:"https://solidus-leipzig.de/backend/api/offers/offers.php",
    //})
    .then((response) => {

        console.log(response.data)  
        setOffers(response.data);
        window.sessionStorage.setItem("SolidusOffers",JSON.stringify(response.data));
        //response.data.map((item) => {
          //console.log(item)
          //console.log(item.id)  
        //})
        
        
    }).catch( (error) => {
          console.log('Da ist etwas schiefgelaufen!');
          console.log('Error '+error.response+' '+error.request+' '+error.message)
          setaxiosError('Error '+error.response+' '+error.request+' '+error.message);
          setglobalLoadError(true); //set to true for functionality, for demo set on false 
        })
  },[])
  
 //if everything is fine 
  if (!globalLoadError && offers !== undefined ) {
  return (
    
    <motion.div 
        className=''
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opactiy:0}}
    >
      
      <div className='offersContent'>
       <div className='offersHeaderSectionWrapper'> 
        <div className='offersHeaderSection'>
          <div className='offersHeader'>
            <h2><span style={{color:"#575656"}}>AN</span>GEBOTE</h2>
          </div>
          <div className='orangeUnderline'></div>
          <div className='greyUnderline'></div>
          
        </div>
        <div className='contactData'>
            <div className='contactItem'><GiSmartphone className='contactIcons'/><p>+49 170 54 88 566</p></div>
            <div className='contactItem'><AiOutlineMail className='contactIcons'/><p><a href="mailto:info@solidus-leipzig.de">info@solidus-leipzig.de</a></p></div>
            <div className='contactItem'><FaMapMarkerAlt className='contactIcons'/><div><p>Lausner Weg 17A</p><p>04207 Leipzig</p></div></div>
          </div>
       </div> 

      <div className='offersCardSectionWrapper'>
        <div className='offersCardSection'>
          
        {offers.map((item) => (  
        <div className='offersCard' key={item.id}>
            <div className='offersCardContent'>
              <img src={NoImg} alt='Modellhaus - Kein Bild vorhanden - aktuelle Bilder bald'/>
              <div className='offersCardTitle'>{item.name}</div>
              <div className='offersCardSubTitle'>{item.sell}</div>
              
              <div className='offersCardInfosWrapper'> 
                <div className='offersCardInfos'>

                  <h4>Wohnfläche: <span>{item.groesse}</span></h4>
                  <h4>Zimmer: <span>{/*item.description.zimmer*/} n.a</span></h4>
                  <h4>Grundstück: <span>{item.grundstueck}</span></h4>
                  <h4>Preis: <span>{item.preis} €</span></h4>
                </div>
              </div>
              <div className='offersCardLowerSection'>
                <div className='offersCardButtonSection'>
                <NavLink to={`/angebote/details/${item.id}`}><button className='offersButton'>Details <MdArrowForwardIos className='offersIcon' style={{color:'#fbbd5c'}}/> </button></NavLink>
                <NavLink to={'/kontakt/'+item.name}><button className='offersButton'>Anfragen <MdArrowForwardIos className='offersIcon' style={{color:'#fbbd5c'}}/> </button></NavLink>
                </div>
                <p>Angaben ohne Gewähr. Bitte erfragen Sie das Exposé.</p>
                </div>
            </div>
          </div>
  ))}
          
 
        </div>
      </div> 
        
      </div>    
        
    </motion.div>
  )} else if (loading) { 
    //if we are still loading 
    return (
      <motion.div 
    className=''
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opactiy:0}}
>
  
  <div className='offersContent'>
   <div className='offersHeaderSectionWrapper'> 
    <div className='offersHeaderSection'>
      <div className='offersHeader'>
        <h2><span style={{color:"#575656"}}>AN</span>GEBOTE</h2>
      </div>
      <div className='orangeUnderline'></div>
      <div className='greyUnderline'></div>
      
    </div>
    <div className='contactData'>
        <div className='contactItem'><GiSmartphone className='contactIcons'/><p>+49 170 54 88 566</p></div>
        <div className='contactItem'><AiOutlineMail className='contactIcons'/><p><a href="mailto:info@solidus-leipzig.de">info@solidus-leipzig.de</a></p></div>
        <div className='contactItem'><FaMapMarkerAlt className='contactIcons'/><div><p>Lausner Weg 17A</p><p>04207 Leipzig</p></div></div>
      </div>
   </div> 

  <div className='offersCardSectionWrapper'>
    <div className='offersCardSection'>
      <div className='offersLoadingComponent'>
      	<ThreeDots height="80" width="80" radius="9" color='#fbb24c' ariaLabel='three-dots-loading' wrapperStyle={{}} wrapperClass='' visible={true}/> 
      </div>  
    </div>
  </div> 
    
  </div>    
    
</motion.div>
    )
  } else 
  return ( <motion.div 
    className=''
    initial={{opacity:0}}
    animate={{opacity:1}}
    exit={{opactiy:0}}
>
  
  <div className='offersContent'>
   <div className='offersHeaderSectionWrapper'> 
    <div className='offersHeaderSection'>
      <div className='offersHeader'>
        <h2><span style={{color:"#575656"}}>AN</span>GEBOTE</h2>
      </div>
      <div className='orangeUnderline'></div>
      <div className='greyUnderline'></div>
      
    </div>
    <div className='contactData'>
        <div className='contactItem'><GiSmartphone className='contactIcons'/><p>+49 170 54 88 566</p></div>
        <div className='contactItem'><AiOutlineMail className='contactIcons'/><p><a href="mailto:info@solidus-leipzig.de">info@solidus-leipzig.de</a></p></div>
        <div className='contactItem'><FaMapMarkerAlt className='contactIcons'/><div><p>Lausner Weg 17A</p><p>04207 Leipzig</p></div></div>
      </div>
   </div> 

  <div className='offersCardSectionWrapper'>
    <div className='offersCardSection'>

      <div className='offersError'>
        <h2>Oops!</h2>
        <p>Die Angebote konnten leider nicht geladen werden, da ist wohl etwas schiefgelaufen.</p>
        <p>Gerne senden wir Ihnen jederzeit unsere aktuellen Angebote auch per E-Mail zu. Treten Sie dazu gerne per Kontaktformular, E-Mail oder Anruf mit uns in Verbindung.</p>
        <br></br>
        <NavLink to='/kontakt/angebote'><button className='offersButton'>Anfragen <MdArrowForwardIos className='offersIcon' style={{color:'#fbbd5c'}}/> </button></NavLink>
        <p>Wenn sie diese Anzeige erneut sehen nehmen Sie bitte Kontakt mit uns auf.</p>
        <p style={{fontFamily:"serif",color:"red"}}>{axiosError}</p>
        
        <NavLink to='/kontakt/stoerung'><button className='offersButton'>Störung melden <MdArrowForwardIos className='offersIcon' style={{color:'#fbbd5c'}}/> </button></NavLink>
        </div>  
    

    </div>
  </div> 
    
  </div>    
    
</motion.div>)
}

export default Offers