import './Home.css'
import React from 'react'
import {motion} from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { MdArrowForwardIos } from 'react-icons/md';

import BackgroundImg from '../img/townhouse_bg.jpg'

function Home() {

  const HomeHeaderStyle = {
    backgroundImage: `url(${BackgroundImg})`,
    height:'74vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize:'cover',
    opacity:'.15',
    marginBottom:'',
    
       
  }
 
  return (
    <motion.div 
        className=''
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opactiy:0}}
        
    >      
          <div style={HomeHeaderStyle} className='homeheaderimg'></div>
          <div className='Content'>
   
          <div className='HomeTitleSection'>
            <div className='HomeTitleRow1'><div>Ihre</div> <div className='SOLIDUS'>Solidus</div></div>
            <div className='HomeTitleRow2'>BAUBERATUNG <span className='UNDStyle'>&amp;</span></div>
            <div className='HomeTitleRow3'>IMMOBILIENAGENTUR</div>

            <div className='HomeTitleButtonSection'>
            <NavLink to='/angebote'><button className='HomeButton'>Angebote ansehen <MdArrowForwardIos className='serviceIcon' style={{color:'#fbbd5c'}}/> </button></NavLink>
            <NavLink to={'/kontakt/service'}><button className='HomeButton'>Service anfragen <MdArrowForwardIos className='serviceIcon' style={{color:'#fbbd5c'}}/> </button></NavLink>
            </div>
          </div>
          
          </div>
        
    </motion.div>
  )
}

export default Home