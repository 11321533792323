import './App.css';
import {BrowserRouter, NavLink} from "react-router-dom";
import Pages from './pages/Pages';
import Logo from './img/Logo_SOLIDUS.jpg'
import Footer from './components/Footer';
import {FaBars} from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { useState } from 'react';


function App() {

   //could use this for dynamic styling ppeepe
   const currentTime = new Date().toLocaleTimeString();
   console.log('No errors just time: '+currentTime);
   //or delete
    
   const [navIsExpanded,setnavIsExpanded] = useState(false)


  return (
    <div className="App">
     <BrowserRouter>
       <div className='navigation'>
          
          <NavLink className={'navlogo'} to={'/'} ><img src={Logo} alt="Logo Solidus GmbH"/></NavLink>
            <div className={navIsExpanded ? 'navmenu expanded' :'navmenu'}>
              <div className='navitems'>
              <NavLink className={({isActive}) => isActive? 'navlinks'+' active' : 'navlinks'} to={'/'}>Home</NavLink>
              <NavLink className={'navlinks'} to={'angebote'}>Angebote</NavLink>
              <NavLink className={'navlinks'} to={'ueber'}>Über uns</NavLink>
              <NavLink className={'navlinks'} to={'kontakt'}>Kontakt</NavLink>
              </div>
            </div>
          <div className='FaBars'><IconContext.Provider value={{style: {verticalAlign: 'bottom'}}} ><FaBars onClick={() => {setnavIsExpanded(!navIsExpanded)}}/></IconContext.Provider></div>
          
        </div>
        <Pages onScroll={()=> {setnavIsExpanded(!navIsExpanded)}}/> 
        <Footer /> 
      </BrowserRouter>
    </div>
  );
}

export default App;
