import React from 'react'
import Ruin from '../img/ruin.jpg'



function PageNotFound() {
const myStyle = {
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    fontSize:"24px", 
    color:"#fbb24c"  
}
    return (
    <div style={myStyle}><p>Hier ist nichts was gefunden werden will </p><p style={{color:'#565757'}}>404 ERROR PAGE NOT FOUND</p>
        <img src={Ruin} alt="Ruine" style={{width:"50vw"}}/>
    </div>
    
  )
}

export default PageNotFound