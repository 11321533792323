import axios from 'axios'


const api = axios.create({
    //baseURL:'http://localhost:8000',
    baseURL:'https://api.solidus-leipzig.de',
    withCredentials:true,
});


export default api