import React from 'react'
import { Navigate, useNavigate, useParams, NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import {MdArrowForwardIos} from 'react-icons/md'
import {GiSmartphone} from 'react-icons/gi'
import {AiOutlineMail} from 'react-icons/ai'
import {FaMapMarkerAlt} from 'react-icons/fa'
import './Details.css'
import { useEffect } from 'react'
import { useState } from 'react'
import NoImg from '../img/house_modell.jpg'
import { IconContext } from 'react-icons/lib'


function Details() {

    const [sellName, setSellName] = useState();   

    const {id} = useParams();
   
   
      const sessionData = JSON.parse(window.sessionStorage.getItem("SolidusOffers"));
      const itemData = sessionData[id-1];
      
      
  if(itemData !==null){
  return (
    <motion.div 
        className=''
        initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opactiy:0}}
    >
      
      <div className='detailsContent'>
       <div className='detailsHeaderSectionWrapper'> 
        <div className='detailsHeaderSection'>
          <div className='detailsHeader'>
            <h2><span style={{color:"#575656"}}>DE</span>TAILS</h2>
          </div>
          <div className='orangeUnderline'></div>
          <div className='greyUnderline'></div>
          
        </div>
        <div className='contactData'>
            <div className='contactItem'><GiSmartphone className='contactIcons'/><p>+49 170 54 88 566</p></div>
            <div className='contactItem'><AiOutlineMail className='contactIcons'/><p><a href="mailto:info@solidus-leipzig.de">info@solidus-leipzig.de</a></p></div>
            <div className='contactItem'><FaMapMarkerAlt className='contactIcons'/><div><p>Lausner Weg 17A</p><p>04207 Leipzig</p></div></div>
          </div>
       </div> 

      <div className='detailsCardSectionWrapper'>
        <div className='detailsCardSection'>
          <div className='detailsCard'>
            <div className='detailsCardContent'>
              <img src={NoImg} alt='Modellhaus - Kein Bild vorhanden - aktuelle Bilder folgen bald' />
            
              <div className='detailsCardInfosWrapper'> 
                <div className='detailsCardInfos'>

                  <h4>Wohnfläche: <span>{itemData.groesse}</span></h4>
                  <h4>Zimmer: <span>{/*itemData.description.zimmer*/}</span></h4>
                  <h4>Grundstück: <span>{itemData.grundstueck}</span></h4>
                  

                  {itemData.sell === 'Mieten' && <h4>Mietpreis: <span>{itemData.preis} €</span></h4>}
                  {itemData.sell === 'Kaufen' && <h4>Kaufpreis: <span>{itemData.preis} €</span></h4>}              
                  
                
                
                
                </div>
              </div>

              <div className='detailsCardButtonSection'>
                <NavLink to={'/kontakt/'+itemData.name}><button className='detailsKontaktButton'>Anfragen <IconContext.Provider value={{style: {verticalAlign:'middle'}}}><MdArrowForwardIos className='detailsKontaktIcon' style={{color:'#fbbd5c'}}/></IconContext.Provider> </button></NavLink>   
                <div className='detailsMore'>
                  <p>Für weitere Informationen, beispielsweise <span style={{fontWeight:'bold'}}>Grundriss</span>, <span style={{fontWeight:'bold'}}>Exposé</span>, <span style={{fontWeight:'bold'}}>weitere Bilder</span> und <span style={{fontWeight:'bold'}}>Besichtigungsmöglichkeiten</span> fragen sie uns gerne per E-Mail, Telefon oder unser Kontaktformular an.</p>
                </div>
              </div>
              
            </div>
          </div>
          
        </div>

        <div className='detailsDescriptionSection'>
            <h1>{itemData.name}</h1>
            <p>{itemData.vollText}</p>
            <h2 className='detailsSubtitle'>Lage</h2>
            <p>{itemData.lageText}</p>
            <h2 className='detailsSubtitle'>Ausstattung</h2>
            <p>{itemData.ausStattungText}</p>

            

        </div>
      </div> 
        
      </div>    
        
    </motion.div>
  )} else {
    return (<div> <Navigate to='/angebote' />;</div>)
  }
}

export default Details