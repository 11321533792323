import './Contact.css'
import React from 'react'
import { motion } from 'framer-motion'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {GiSmartphone} from 'react-icons/gi'
import {AiOutlineMail} from 'react-icons/ai'
import {MdArrowForwardIos} from 'react-icons/md'
import {RiPrinterCloudLine} from 'react-icons/ri'
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ContactForm from '../components/ContactForm'


function Contact() {
  
  const {subject} = useParams();

    return (
      <motion.div 
      className=''
      initial={{opacity:0}}
      animate={{opacity:1}}
      exit={{opactiy:0}}
    
  >     
    
      <div className='content'>

        
      <div className='contactHeader'>
        <div className='contactHeaderTitle'>
          <h2>Kontaktieren <span style={{color:"#fbbd5c"}}>Sie uns</span></h2>
          <div className='orangeUnderline'></div>
          <div className='greyUnderline'></div>
        </div>
      </div>

      <div className='cardWrapper'>
        <div className='contactCard'>
          
          <ContactForm subject={subject}/>
          
          <div className='contactDataWrapper'>
            <div className='contactData'>
              <div className='contactItem'><GiSmartphone className='contactIcons'/><p>+49 170 54 88 566</p></div>
              <div className='contactItem'><RiPrinterCloudLine className='contactIcons'/><p>+49 341 422 59 53</p></div>
              <div className='contactItem'><AiOutlineMail className='contactIcons'/><p><a href="mailto:info@solidus-leipzig.de">info@solidus-leipzig.de</a></p></div>
              <div className='contactItem'><FaMapMarkerAlt className='contactIcons'/><div><p>Lausner Weg 17A</p><p>04207 Leipzig</p></div></div>
            </div>
          </div>
        </div>
      </div>  
    </div>
      
    
  </motion.div>
    )
  
}

export default Contact