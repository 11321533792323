import React from 'react'
import {Route, Routes, useLocation } from 'react-router-dom'
import Offers from './Offers'
import Contact from './Contact'
import About from './About'
import Home from './Home'
import Datenschutz from './Datenschutz'
import Impressum from './Impressum'
import PageNotFound from './PageNotFound'
import Details from './Details'

import {AnimatePresence} from 'framer-motion'

function Pages() {

    const location = useLocation();

  return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>

                <Route path='/' element={<Home />}/> 
                <Route path='/angebote' element={<Offers />}/> 
                <Route path='/ueber' element={<About />}/> 
                <Route path='/kontakt' element={<Contact />} />
                <Route path='/kontakt/:subject' element={<Contact />} /> 
                <Route path='/datenschutz' element={<Datenschutz />} />
                <Route path='/impressum' element={<Impressum />} />
                <Route path='/angebote/details/:id' element={<Details />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        </AnimatePresence>
  )
}

export default Pages